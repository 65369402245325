import React from 'react'
import Helmet from 'react-helmet'
import {
  Layout,
  BannerSmall,
  Breadcrumbs,
  TextBox,
  BlogSlider,
  BannerLinks,
  CallUs,
  ImageSlider
} from '../../components'

import BannerImage from '../../assets/projektuebersicht.jpg'

import cards from '../../components/BlogSlider/DefaultCards'
import boxes from '../../components/BannerLinks/DefaultBox'
import contacts from '../../components/CallUs/DefaultContacts'

import spurenimsand from '../../assets/spurenimsand.jpg'
import letsgo from '../../assets/letsgo.jpg'
import fels from '../../assets/fels.jpg'

const images = [
  {
    image: spurenimsand,
    alt: "Spuren im Sand"
  },
  {
    image: fels,
    alt: "Kletterer"
  },
  {
    image: letsgo,
    alt: "Strand"
  }
]

import { generateBreadcrumbsLinks } from '../../utils/index'

const BildBefuellterText = () => {
  const links = generateBreadcrumbsLinks('/blog/bild-befuellter-text', 'Text mit Bild befüllt')
  return (
    <Layout>
      <Helmet
        title="Bild-befüllter Text"
        meta={[
          {
            name: 'description',
            content:
              'Ein UI (User-Interface) Trend dieses Jahres ist: Text mit einem Hintergrundbild befüllt.'
          },
          {
            name: 'keywords',
            content:
              'Bild-befüllter Text holzweg, Blog holzweg, Innsbruck, holzweg, Blog, Bild-befüllter Text'
          },
          {
            name: 'image',
            content:
              'https://www.holzweg.com/static/spurenimsand-3f452f5b0b9260395a46c7f5db5f77ed.jpg'
          },
          {
            property: 'og:description',
            content:
              'Ein UI (User-Interface) Trend dieses Jahres ist: Text mit einem Hintergrundbild befüllt.'
          },
          {
            property: 'og:image',
            content:
              'https://www.holzweg.com/static/spurenimsand-3f452f5b0b9260395a46c7f5db5f77ed.jpg'
          }
        ]}
      />
      <BannerSmall
        image={BannerImage}
        title={
          <span>
            Neugierig bleiben
            <br />
            Neues lernen
          </span>
        }
      />
      <Breadcrumbs
          backLinkAlias=' '
          links={links}
        />
      <TextBox
        title="Bild-befüllter Text"
        text="Ein UI (User-Interface) Trend dieses Jahres ist: Text mit einem Hintergrundbild befüllt. Meistens wird dazu das prominenteste Wort oder Phrase auf der Seite dazu verwendet - das kann der Name eines Produkts sein, der Name einer Firma, die Überschrift oder ein Slogan. Der Text gewinnt an Raffinesse und wird zum Teil der Komposition. Hier ein paar Beispiele: "
      />
      <ImageSlider images={images} />
      <TextBox
        title=""
        text={
          <span>
            <h2>Dir gefällt, was Du siehst?</h2>
            <p>Dann melde dich doch bei uns, wir haben noch ein paar weitere Design-Ideen in petto!
            </p>
          </span>
        }
      />
      <CallUs contacts={contacts} title="Wir lieben Design" />
      <BlogSlider cards={cards} singleRow={true} title="Weitere Blogeinträge" />
      <TextBox
        title="Walk with us on the holzweg"
        text="Ready für uns? Dann kontaktiere uns zu einem unverbindlichen Beratungsgespräch oder verwende unsere Call-Back Funktion. Wenn der holzweg nach Traumjob-Garantie klingt, dann schaue bei den Stellenangeboten rein oder besuche uns in den sozialen Medien!"
      />
      <BannerLinks boxes={boxes} />
    </Layout>
  )
}


export default BildBefuellterText
